import api from "./api.js";

var driver_term = localStorage.getItem("driver_term") || "Driver";

export default {
  data: () => ({
    zoom: 11,
    snackbar: false,
    text: "",
    title: "Last Location",
    // center: (sessionStorage.getItem("tenant_type") ?? null) === "namat" ? { lat: 25.3548, lng: 51.1838 } : { lat: 25.0657, lng: 55.17128 },
    center: { lat: parseFloat(localStorage.getItem("lat") ?? "51.1838"), lng: parseFloat(localStorage.getItem("lon")?? "55.17128") },    window_open: false,
    info_marker: null,
    infowindow: { lat: 10, lng: 10.0 },
    selected_driver: null,
    driverName: null,
    info: null,
    drivers: [{ id: -1, fullname: "ALL" }],
    items: [],
    showingsolo: true,
    firstInterval: null,
    secondInterval: null,
    panned: false,
    path: [],
    markers: [],
    fdate: null,
    fmenu: false,
    show1: false,
    driver_term: driver_term,
  }),

  methods: {
    reset() {
      this.window_open = false;
      this.getrecent();
    },
    cleanAndWait() {
      this.path = [];
      this.markers = [];
      window.clearInterval(this.firstInterval);
      window.clearInterval(this.secondInterval);
      if (this.title == "Location") this.focus();
      else {
        this.getrecent();
      }
    },
    focus() {
      window.clearInterval(this.firstInterval);
      window.clearInterval(this.secondInterval);
      this.pannned = false;
      this.title = "Location";
      this.markers = [];
      this.path = [];
      this.window_open = false;
      if (this.selected_driver == null) return;
      if (this.selected_driver.id == -1) {
        this.$refs.map.$mapPromise.then((map) => {
          map.panTo(this.center);
        });
        this.reset();
        return;
      }
      this.loading = true;
      api
        .GetDriverLocationsForToday(this.selected_driver.id, this.fdate)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            if (res.data.data.locations.length != 0) {
              res.data.data.locations.forEach((location) => {
                let found = false;
                for (let index = 0; index < this.path.length; index++) {
                  const p = this.path[index];
                  if (location.id == p.id) found = true;
                }
                if (!found) {
                  this.path.push({
                    id: location.id,
                    lat: parseFloat(location.lat),
                    lng: parseFloat(location.lon),
                  });
                  this.markers.push({
                    id: location.id,
                    icon: require("@/assets/images/icons/bus.svg"),
                    info: location.date_and_time,
                    driverName: res.data.data.fname + " " + res.data.data.lname,
                    driver_id: res.data.data.id.toString(),
                    position: {
                      lat: parseFloat(location.lat),
                      lng: parseFloat(location.lon),
                    },
                  });
                }
              });
              this.$refs.map.$mapPromise.then((map) => {
                map.panTo(this.markers[0].position);
              });
            }
          }
        })
        .catch((error) => {
          this.error = error;
          this.loading = false;
          this.errorDialog = true;
        });

      clearInterval(this.firstInterval);
      this.secondInterval = setInterval(() => {
        if (this.$route.name == "Map")
          api
            .GetDriverLocationsForToday(this.selected_driver.id, this.fdate)
            .then((res) => {
              this.loading = false;
              this.loading = false;
              if (res.data.responseCode === 1) {
                if (res.data.data.locations.length != 0) {
                  res.data.data.locations.forEach((location) => {
                    let found = false;
                    for (let index = 0; index < this.path.length; index++) {
                      const p = this.path[index];
                      if (location.id == p.id) found = true;
                    }
                    if (!found) {
                      this.path.push({
                        id: location.id,
                        lat: parseFloat(location.lat),
                        lng: parseFloat(location.lon),
                      });
                      this.markers.push({
                        id: location.id,
                        icon: require("@/assets/images/icons/bus.svg"),
                        info: location.date_and_time,
                        driverName:
                          res.data.data.fname + " " + res.data.data.lname,
                        driver_id: res.data.data.id.toString(),
                        position: {
                          lat: parseFloat(location.lat),
                          lng: parseFloat(location.lon),
                        },
                      });
                    }
                  });
                  this.$refs.map.$mapPromise.then((map) => {
                    map.panTo(this.markers[0].position);
                  });
                }
              }
            })
            .catch((error) => {
              this.error = error;
              this.loading = false;
              this.errorDialog = true;
            });
      }, 10000);
    },
    openWindow(index, driverName, info) {
      this.infowindow = this.markers[index].position;
      this.driverName = driverName;
      this.info = this.convertUTCDateToLocalDate(info);
      this.window_open = true;
    },
    convertUTCDateToLocalDate(utcDate) {
      if (utcDate) {
        const date = new Date(utcDate);
        var newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString();
      }
    },
    getrecent() {
      this.title = "Last Location";
      this.path = [];
      this.markers = [];
      window.clearInterval(this.firstInterval);
      window.clearInterval(this.secondInterval);
      api
        .GetDriversRecentLocations(this.fdate)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            res.data.data.forEach((element) => {
              if (element.locations.length != 0) {
                let found = false;
                for (let index = 0; index < this.markers.length; index++) {
                  const p = this.markers[index];
                  if (element.locations[0].id == p.id) found = true;
                }
                if (!found && element.locations.length != 0) {
                  this.markers.push({
                    id: element.locations[0].id,
                    icon: require("@/assets/images/icons/bus.svg"),
                    info: element.locations[0].date_and_time,
                    driverName: element.fname + " " + element.lname,
                    driver_id: element.id.toString(),
                    position: {
                      lat: parseFloat(element.locations[0].lat),
                      lng: parseFloat(element.locations[0].lon),
                    },
                  });
                }
              }
            });
          }
        })
        .catch((error) => {
          this.error = error;
          this.loading = false;
          this.errorDialog = true;
        });
      this.firstInterval = setInterval(() => {
        if (this.$route.name == "Map")
          api
            .GetDriversRecentLocations(this.fdate)
            .then((res) => {
              this.loading = false;
              if (res.data.responseCode === 1) {
                res.data.data.forEach((element) => {
                  let found = false;
                  for (let index = 0; index < this.markers.length; index++) {
                    const p = this.markers[index];
                    if (element.id == p.id) found = true;
                  }
                  if (!found && element.locations.length != 0) {
                    this.markers.push({
                      id: element.id,
                      icon: require("@/assets/images/icons/bus.svg"),
                      info: element.locations[0].date_and_time,
                      driverName: element.fname + " " + element.lname,
                      driver_id: element.id.toString(),
                      position: {
                        lat: parseFloat(element.locations[0].lat),
                        lng: parseFloat(element.locations[0].lon),
                      },
                    });
                  }
                });
              }
            })
            .catch((error) => {
              this.error = error;
              this.loading = false;
              this.errorDialog = true;
            });
      }, 10000);
    },
  },
  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 3;
        }
        case "xl": {
          return 3;
        }
      }
    },
  },
  mounted() {
    var myCurrentDate = new Date();
    this.fdate = myCurrentDate.toISOString().substr(0, 10);
    this.getrecent();

    api
      .getdrivers()
      .then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            let d = {
              id: element.id,
              fullname: element.fname + " " + element.lname,
            };
            this.drivers.push(d);
          });
        }
      })
      .catch((error) => {
        this.text = error;
        this.snackbar = true;
        this.loading = false;
        this.errorDialog = true;
      });
    this.loading = true;
    api
      .getdrivers()
      .then((res) => {
        this.loading = false;
        if (res.data.responseCode === 1) {
          this.items = res.data.data;
        }
      })
      .catch((error) => {
        this.error = error;
        this.loading = false;
        this.errorDialog = true;
      });
  },
};
