import api from "./api.js";
import settings from "../../plugins/settings.js";

var driver_term = localStorage.getItem("driver_term") || "Driver";
var trip_term = localStorage.getItem("trip_term") || "Trip";
var currency = settings.fromNameToSymbol(localStorage.getItem("currency") || "Euro");


export default {
  data: () => ({
    select: { state: "Postponed", abbr: "PP", value: 1 },
    items: [
      { state: "Not paid", abbr: "NP", value: 0 },
      { state: "Paid", abbr: "P", value: 2 },
      { state: "Postponed", abbr: "PP", value: 1 },
    ],
    ////
    isLoading1: false,
    model1: null,
    search1: null,
    items1: [],
    ////
    clicked: false,
    valid: false,
    errormessages: null,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    search: "",
    id: null,
    isrequired: true,
    drivers: [],
    myCurrentData: [],
    trips: [],
    warehouses: [],
    selected_driver: null,
    selected_trip: null,
    amount: 0.0,
    description: null,
    issued_date: null,
    status: 1,
    driver_term: driver_term,
    trip_term: trip_term,
    currency: currency,
    json_fields: {
      Id: "id",
      "First Name": "driver.fname",
      "Last Name": "driver.lname",
      [`${trip_term} Id`]:  "trip.id",
      amount: "amount",
      description: "description",
      Date: "issued_date",
      "Assigned Date": "creation_date",
      Status: "statusText",
    },
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && v.length <= 10) || "Password must be less than 10 characters",
      (v) => (v && v.length >= 4) || "Minimum 4 Characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    NormalRules: [(v) => !!v || "Field is required"],
    noRules: [],
    snackbar: false,
    textProps: {
      "v-model": "msg",
    },
    dateProps: {
      color: "primary",
    },
    timeProps: {
      format: "24hr",
      color: "primary",

      landscape: true,
    },
    text: "",
    fdate: null,
    tdate: null,
    fmenu: false,
    tmenu: false,
    show1: false,
    ddata: [],
    headers: [
      {
        text: driver_term + " Name",
        align: "center",
        value: "driver",
      },
      {
        text: trip_term,
        align: "center",
        value: "tripDetails",
      },
      {
        text: "Amount",
        value: "amount",
        align: "center",
      },
      {
        text: "Description",
        value: "description",
        align: "center",
      },
      {
        text: "Date",
        value: "issued_date",
        align: "center",
      },
      {
        text: "Assigned Date",
        value: "creation_date",
        align: "center",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },

      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
  }),
  mounted() {
    var myCurrentDate = new Date();
    var myFutureDate = new Date();

    this.fdate = myCurrentDate.toISOString().substr(0, 10);
    this.tdate = myFutureDate.toISOString().substr(0, 10);

    api
      .getDrivers()
      .then((res) => {
        this.loading = false;
        if (res.data.responseCode === 1) {
          this.drivers = res.data.data;
        }
      })
      .catch((error) => {
        this.error = error;
        this.loading = false;
        this.errorDialog = true;
      });
    this.fetch();
    // api.getTrips().then((res) => {
    //     this.loading = false;
    //     if (res.data.responseCode === 1) {
    //         res.data.data.forEach(element => {
    //             element.brief = element.id + ' - ' + element.driver.fname + ' ' + element.driver.lname + ' - ' + element.start_date + ' - ' + element.type0.title;
    //             this.trips.push(element)
    //         });
    //     }
    // }).catch((error) => {
    //     this.error = error;
    //     this.loading = false;
    //     this.errorDialog = true;
    // });
  },
  computed: {
    passwordConfirmationRule() {
      return () => this.p1 === this.p2 || "Password must match";
    },
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
  },
  watch: {
    search1() {
      // Items have already been loaded
      if (this.items1.length > 0) return;

      this.isLoading1 = true;

      // Lazily load input items
      api
        .getTrips(this.selected_driver.id)
        .then((res) => {
          res.data.data.forEach((element) => {
            element.brief =
              element.start_date +
              " - " +
              element.driver.fname +
              " " +
              element.driver.lname +
              " - " +
              element.type0.title;
          });
          this.items1 = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading1 = false));
    },

    p1: {
      handler(value) {
        if (value.toString().length > 0) {
          this.isrequired = true;
        } else {
          this.isrequired = false;
        }
      },
    },
    p2: {
      handler(value) {
        if (value.toString().length > 0) {
          this.isrequired = true;
        } else {
          this.isrequired = false;
        }
      },
    },
  },
  methods: {
    transformTripDetails(trip) {
      var tripDetails =
        trip.start_date +
        "->" +
        this.warehouses.find((warehouse) => {
          return warehouse.id === trip.warehouse_id;
        }).title;

      return tripDetails;
    },
    fetch() {
      this.ddata = [];
      api
        .getWarehouses()
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode == 1) {
            this.warehouses = res.data.data;
          }
        })
        .catch((error) => {
          this.error = error;
          this.loading = false;
          this.errorDialog = true;
        });

      api
        .getFines(this.fdat, this.tdate)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            res.data.data.forEach((element) => {
              switch (element.status) {
                case "0":
                  element.statusText = "No Paid";
                  break;
                case "1":
                  element.statusText = "PostPoned";
                  break;
                case "2":
                  element.statusText = "Paid";
                  break;
                default:
                  break;
              }

              element.tripDetails = this.transformTripDetails(element.trip);

              this.ddata.push(element);
            });
          }
        })
        .catch((error) => {
          this.error = error;
          this.loading = false;
          this.errorDialog = true;
        });
    },
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    convertToUTCDate(date) {
      const localDate = new Date();
      const utcDate = new Date(date ? date : localDate);
      const utcDateTime = new Date(utcDate.toISOString());

      // Get date components
      const year = utcDateTime.getUTCFullYear();
      const month = utcDateTime.getUTCMonth() + 1; // Months are zero-indexed
      const day = utcDateTime.getUTCDate();
      const hours = utcDateTime.getUTCHours();
      const minutes = utcDateTime.getUTCMinutes();
      const seconds = utcDateTime.getUTCSeconds();

      // Format the date
      const formattedDate = `${year}-${this.pad(month)}-${this.pad(
        day
      )} ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;

      return formattedDate;
    },
    pad(number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    },
    convertUTCDateToLocalDate(utcDate) {
      if (utcDate) {
        const date = new Date(utcDate);
        var newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString();
      }
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    showDialog(item) {
      this.valid = true;
      if (item == null) {
        this.model1 = null;
        this.items1 = [];
        this.isrequired = true;
        this.selectedObject = { id: -1 };
        this.selected_driver = null;
        this.selected_trip = null;
        this.amount = null;
        this.description = null;
        (this.select = { state: "PostPoned", abbr: "PP", value: 2 }),
          (this.issued_date = new Date(Date.now()));
      } else {
        this.selectedObject = item;
        this.isrequired = false;
        this.selected_driver = item.driver;
        this.selected_trip = item.trip;
        this.amount = item.amount;
        this.description = item.description;

        switch (item.status) {
          case "0":
            this.select = { state: "Not Paid", abbr: "NP", value: 0 };
            break;
          case "1":
            this.select = { state: "PostPoned", abbr: "PP", value: 1 };
            break;
          case "2":
            this.select = { state: "Paid", abbr: "P", value: 2 };
            break;
          default:
            break;
        }
        this.issued_date = new Date(item.issued_date);
      }
      this.dialog = true;
    },
    agreeOnDelete() {
      this.clicked = true;
      this.loading = true;

      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;

          this.deleteDialog = false;
          this.clicked = false;
          if (res.data.responseCode === 1) {
            var index = this.ddata.indexOf(this.selectedObject);

            this.$delete(this.ddata, index);
            this.text = "Fine deleted succesfully.";
            this.snackbar = true;
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.text = error;
          this.snackbar = true;
          this.deleteDialog = false;
          this.clicked = false;
        });
    },
    save() {
      this.clicked = true;
      if (!this.$refs.form.validate()) {
        this.clicked = false;
        return;
      }
      this.loading = true;
      if (this.selectedObject.id != -1) {
        api
          .save(
            this.selectedObject.id,
            this.selected_driver.id,
            this.selected_trip != null ? this.selected_trip.id : null,
            parseFloat(this.amount).toFixed(2),
            this.description,
            this.convertToUTCDate(this.issued_date),
            parseInt(this.select.value)
          )
          .then((res) => {
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              var index = this.ddata.indexOf(this.selectedObject);
              this.ddata[index].driver = this.selected_driver;

              this.ddata[index].tripDetails = this.transformTripDetails(
                this.selected_trip
              );
              this.ddata[index].amount = parseFloat(this.amount).toFixed(2);
              this.ddata[index].description = this.description;
              this.ddata[index].issued_date = this.convertUTCDateToLocalDate(
                this.issued_date
              );
              this.ddata[index].status = this.select.value;

              this.text = "Fine updated successfully.";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      } else {
        api
          .getTrips(this.selected_driver.id)
          .then((res) => {
            if (res.data.responseCode === 1) {
              this.trips = res.data.data;

              this.selected_trip = this.trips.find((x) => {
                return x.id === this.model1;
              });
              api
                .add(
                  this.selected_driver.id,
                  this.selected_trip != null ? this.selected_trip.id : null,
                  parseFloat(this.amount).toFixed(2),
                  this.description,
                  this.convertToUTCDate(this.issued_date),
                  parseInt(this.select.value)
                )
                .then((res) => {
                  this.loading = false;
                  this.dialog = false;
                  this.clicked = false;

                  if (res.data.responseCode === 1) {
                    this.ddata.push({
                      id: res.data.data.id,
                      driver: this.selected_driver,
                      tripDetails: this.transformTripDetails(
                        this.selected_trip
                      ),
                      amount: parseFloat(this.amount).toFixed(2),
                      description: this.description,
                      creation_date: res.data.data.creation_date,
                      issued_date: this.convertUTCDateToLocalDate(
                        this.issued_date
                      ),
                      status: this.select.value,
                    });
                    this.text = "Fine added successfully.";
                    this.snackbar = true;
                  } else {
                    this.text = res.data.responseMessage;
                    this.snackbar = true;
                  }
                })
                .catch((error) => {
                  this.text = error;
                  this.snackbar = true;
                  this.loading = false;
                  this.dialog = false;
                  this.clicked = false;
                });
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      }
    },
  },
};
