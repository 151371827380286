import api from "./api.js";
import destinationApi from "../destination/api.js";

var trip_term = localStorage.getItem("trip_term") || "Trip";
var driver_term = localStorage.getItem("driver_term") || "Driver";
var destination_term = localStorage.getItem("destination_term") || "Destination";


export default {
  data: () => ({
    tenantType: sessionStorage.getItem("tenant_type") ?? null,
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    bigDialog: false,
    search: "",
    tabs: null,
    driverDisable: true,
    selectedItem: 1,
    snackbar: false,
    clicked: false,
    text: "",
    id: null,
    info: [],
    trip_term: trip_term,
    driver_term: driver_term,
    destination_term: destination_term,
    textProps: {
      "v-model": "msg",
    },
    dateProps: {
      color: "primary",
    },
    timeProps: {
      format: "24hr",
      color: "primary",

      landscape: true,
    },
    NormalRules: [(v) => !!v || "Field is required"],
    types: [],
    selected_start_date: null,
    clear: false,
    destinationDialog: false,
    currentDestination: null,
    currentDestinationIndex: null,
    selected_driver: null,
    selected_warehouse: null,
    selected_type: null,
    start_date: null,
    end_date: null,
    repeat_type: null,
    repeat_interval: 1,
    days_of_week: null,
    selectedDays: [],
    data: [],
    pictures: [],
    type: null,
    myCurrentData: [],
    warehouses: [],
    drivers: [],
    repeat_enum: ["daily", "weekly", "monthly"],
    days_enum: [
      { name: "Monday", value: 1 },
      { name: "Tuesday", value: 2 },
      { name: "Wednesday", value: 3 },
      { name: "Thursday", value: 4 },
      { name: "Friday", value: 5 },
      { name: "Saturday", value: 6 },
      { name: "Sunday", value: 7 },
    ],
    infoDialog: false,
    fdate: null,
    tdate: null,
    note: "",
    destinations: [],
    destination_id: null,
    trip_destinations: [],
    place: { name: "", lat: null, lon: null },
    fmenu: false,
    tmenu: false,
    show1: false,
    json_fields: {
      Id: "id",
      [`${driver_term} Name`]: "driver.fullname",
      Warehouse: "warehouse.title",
      Type: "type.title",
      Status: "flag",
      "Start At": "start_date",
      "Picked On": "started_date",
      "Delivered On": "ended_date",
      "Nb of Accidents": "accidents.length",
      "Nb of Fines": "fines.length",
    },
    headers: [
      {
        text:
          (sessionStorage.getItem("tenant_type") ?? null) === "namat"
            ? "Staff"
            : driver_term + " Name",
        value: "driver.fullname",
        align: "center",
      },
      {
        text: "Origin",
        value: "warehouse.title",
        align: "center",
      },
      {
        text: "Type",
        value: "type0.title",
        align: "center",
      },
      {
        text: "Start Date",
        value: "start_date",
        align: "center",
      },
      {
        text: "End Date",
        value: "end_date",
        align: "center",
      },
      {
        text: "Repeat Type",
        value: "repeat_type",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
    title: "",
  }),
  mounted() {
    api
      .gettypes()
      .then((res) => {
        // this.loading = false;
        if (res.data.responseCode === 1) {
          this.types = res.data.data;
        }
      })
      .catch((error) => {
        this.error = error;
        // this.loading = false;
        this.errorDialog = true;
      });
    var myCurrentDate = new Date();
    var myFutureDate = new Date();

    this.fdate = myCurrentDate.toISOString().substr(0, 10);
    this.tdate = myFutureDate.toISOString().substr(0, 10);

    api
      .getwarehouses()
      .then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            let w = { id: element.id, title: element.title };
            this.warehouses.push(w);
          });
        }
      })
      .catch((error) => {
        this.text = error;
        this.snackbar = true;
        // this.loading = false;
        this.errorDialog = true;
      });

    this.fetch();

    setInterval(() => {
      this.fetch();
    }, 60000);

    if ((sessionStorage.getItem("tenant_type") ?? null) === "namat") {
      const headers = [
        {
          text: "Staff",
          value: "driver.fullname",
          align: "center",
        },
        {
          text: "Origin",
          value: "warehouse.title",
          align: "center",
        },
        {
          text: "Type",
          value: "type0.title",
          align: "center",
        },
        {
          text: "Created On",
          value: "created_date",
          align: "center",
        },
        {
          text: "Start Date",
          value: "start_date",
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
        },
      ];

      this.headers = headers;
    }
  },
  methods: {
    parseDate(dateString) {
      if (!dateString) return;

      const [datePart, timePart] = dateString.split(" ");

      if (!datePart || !timePart) {
        console.error("Invalid date format");
        return null;
      }

      const dateParts = datePart.split("-").map(Number);
      const timeParts = timePart.split(":").map(Number);

      if (dateParts.length !== 3 || timeParts.length !== 3) {
        console.error("Incomplete date or time format");
        return null;
      }

      const [year, month, day] = dateParts;
      const [hours, minutes, seconds] = timeParts;

      return new Date(year, month - 1, day, hours, minutes, seconds);
    },

    async fetch() {
      this.data = [];

      await api
        .gettrips()
        .then((res) => {
          if (res.data.responseCode === 1) {
            res.data.data.trips.forEach((element) => {
              element.driver =
                element.driver != null
                  ? {
                      fullname:
                        element.driver.fname + " " + element.driver.lname,
                      id: element.driver.id,
                    }
                  : { fullname: "N" + "/" + "A", id: -1 };
              // element.warehouse = {
              //   title: element.warehouse.title,
              //   id: element.warehouse.id,
              // };

              element.started_date = this.convertUTCDateToLocalDate(
                element.started_date
              );
              element.created_date = this.convertUTCDateToLocalDate(
                element.created_date
              );
              // element.start_date = this.convertUTCDateToLocalDate(
              //   element.start_date
              // );
              // element.end_date = this.convertUTCDateToLocalDate(
              //   element.end_date
              // );

              this.data.push(element);
            });
          }
        })
        .catch((error) => {
          this.text = error;
          this.snackbar = true;
          this.loading = false;
          this.errorDialog = true;
        });

      await destinationApi.getdestinations().then((res) => {
        if (res.data.responseCode === 1) {
          this.destinations = res.data.data;
        }
      });

      this.loading = false;
    },

    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },

    back() {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
    },

    focus(type, info) {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
      this.info = [];
      switch (type) {
        case 0: //Fines
          this.type = type;
          this.info = info;
          break;
        case 1: // Accidents
          this.type = type;
          this.info = info;
          break;
        default:
          break;
      }
      this.infoDialog = true;
    },

    convertUTCDateToLocalDate(utcDate) {
      if (utcDate) {
        const date = new Date(utcDate);
        var newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString();
      }
    },

    convertToUTCDate(date) {
      const localDate = new Date();
      const utcDate = new Date(date ? date : localDate);
      const utcDateTime = new Date(utcDate.toISOString());

      // Get date components
      const year = utcDateTime.getUTCFullYear();
      const month = utcDateTime.getUTCMonth() + 1; // Months are zero-indexed
      const day = utcDateTime.getUTCDate();
      const hours = utcDateTime.getUTCHours();
      const minutes = utcDateTime.getUTCMinutes();
      const seconds = utcDateTime.getUTCSeconds();

      // Format the date
      const formattedDate = `${year}-${this.pad(month)}-${this.pad(
        day
      )} ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;

      return formattedDate;
    },

    pad(number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    },

    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },

    async agreeOnDelete() {
      this.clicked = true;

      this.loading = true;
      await api
        .delete(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.data.indexOf(this.selectedObject);
            this.$delete(this.data, index);
            this.text = trip_term +" Deleted Succesfully";
            this.snackbar = true;
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.errorDialog = true;
          this.deleteDialog = false;
          this.clicked = false;
        });

      await this.fetch();
    },

    async showDialog(item) {
      this.valid = true;
      if (item == null) {
        this.selectedObject = { id: -1 };
        this.selected_driver = null;
        this.selected_warehouse = null;
        this.type = null;
        this.id = null;
        this.selected_start_date = new Date(Date.now());
        this.end_date = new Date(Date.now());

        this.place = { name: null, lat: null, lon: null };
        this.trip_destinations = [];
        this.note = null;
      } else {
        this.selectedObject = item;
        this.selected_warehouse = {
          id: item.warehouse.id,
          title: item.warehouse.title,
        };

        this.selected_driver = {
          id: item.driver.id,
          fullname: item.driver.fullname,
        };

        this.selected_type = {
          id: item.type0.id,
          title: item.type0.title,
        };

        this.id = item.id;
        this.selected_start_date = this.parseDate(item.start_date);
        this.start_date = this.parseDate(item.start_date);
        //Tue Mar 18 1930 16:20:00 GMT+0200 (Eastern European Standard Time)
        this.end_date = this.parseDate(item.end_date);

        this.note = item.note;
        this.destination_id = item.destination_id;
        this.place = {
          name: item.destination_name,
          lat: item.destination_lat,
          lon: item.destination_lon,
        };

        this.repeat_type = item.repeat_type;
        this.selectedDays = item.days_of_week.split(",");
        this.selectedDays = this.selectedDays.map((val) => Number(val));
        this.days_of_week = item.days_of_week;

        this.trip_destinations = item.destinations
          ? item.destinations.map((des) => {
              const lat = des.destination?.lat
                ? des.destination?.lat
                : des.destination_lat;

              const lon = des.destination?.lon
                ? des.destination?.lon
                : des.destination_lon;

              return {
                destination_id: des.destination_id,
                destination_lat: lat,
                destination_lon: lon,
                destination_name: des.destination_name,
                task: des.task,
                client_approval: des.client_approval,
                manager_approval: des.manager_approval,
              };
            })
          : [];
      }

      this.dialog = true;
    },

    async save() {
      this.clicked = true;

      if (!this.$refs.form.validate()) {
        this.text = "Please fill the form correctly";
        this.snackbar = true;
        this.clicked = false;
        return false;
      }
      this.loading = true;

      if (this.selectedObject.id != -1) {
        await api
          .save(
            this.id,
            this.selected_driver.id,
            this.selected_warehouse.id,
            this.selected_type.id,
            this.convertToUTCDate(this.selected_start_date),
            this.note,
            this.trip_destinations,
            this.convertToUTCDate(this.end_date),
            this.repeat_type,
            this.repeat_interval,
            this.days_of_week
          )
          .then((res) => {
            this.resetData();
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              var index = this.data.indexOf(this.selectedObject);
              this.data[index].driver = {
                id: this.selected_driver.id,
                fullname: this.selected_driver.fullname,
              };
              this.data[index].warehouse = {
                id: this.selected_warehouse.id,
                title: this.selected_warehouse.title,
              };
              this.data[index].type0 = this.selected_type;
              this.data[index].start_date = this.convertUTCDateToLocalDate(
                this.selected_start_date
              );

              this.data[index].started_date = this.convertUTCDateToLocalDate(
                this.data[index].started_date
              );
              this.data[index].created_date = this.convertUTCDateToLocalDate(
                this.data[index].created_date
              );
              this.data[index].start_date = this.convertUTCDateToLocalDate(
                this.data[index].start_date
              );
              this.data[index].ended_date = this.convertUTCDateToLocalDate(
                this.data[index].ended_date
              );

              this.text = this.trip_term + " updated successfully.";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
          });
      } else {
        await api
          .add(
            this.selected_driver.id,
            this.selected_warehouse.id,
            this.selected_type.id,
            this.convertToUTCDate(this.selected_start_date),
            this.note,
            this.trip_destinations,
            this.convertToUTCDate(this.end_date),
            this.repeat_type,
            this.repeat_interval,
            this.days_of_week
          )
          .then((res) => {
            this.loading = false;

            this.dialog = false;
            this.clicked = false;
            if (res.data.responseCode === 1) {
              this.data.push({
                id: res.data.data.id,
                driver: {
                  id: res.data.data.driver_id,
                  fullname: this.selected_driver.fullname,
                },
                warehouse: {
                  id: res.data.data.warehouse_id,
                  title: this.selected_warehouse.title,
                },
                type0: this.selected_type,
                start_date: this.convertUTCDateToLocalDate(
                  this.selected_start_date
                ),
                flag: "Not Picked",
                created_date: this.created_date,
                ended_date: null,
                started_date: null,
                accidents: [],
                fines: [],
              });
              this.text = this.trip_term + " added successfully.";
              this.snackbar = true;
            } else {
              this.text = res.data.responseMessage;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error;
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
            this.clicked = false;
          });
      }

      this.selectedObject = { id: -1 };
      this.selected_driver = null;
      this.selected_warehouse = null;
      this.type = null;
      this.id = null;
      this.selected_start_date = new Date(Date.now());
      this.end_date = new Date(Date.now());

      this.place = { name: null, lat: null, lon: null };
      this.trip_destinations = [];
      this.note = null;
      this.selectedDays = [];
      this.days_of_week = null;

      this.fetch();
    },

    onPlaceSelected(place, index) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      // this.place = { name: place.name, lat: latitude, lon: longitude };

      this.trip_destinations[index].destination_id = null;
      this.trip_destinations[index].destination_name = place.formatted_address;
      this.trip_destinations[index].destination_lat = latitude;
      this.trip_destinations[index].destination_lon = longitude;
    },

    onPlaceInputChange(place, index) {
      this.trip_destinations[index].destination_name =
        place === "" ? null : place;
      this.trip_destinations[index].destination_lat = null;
      this.trip_destinations[index].destination_lon = null;
    },

    closeDialog() {
      this.dialog = false;
      this.selectedObject = { id: -1 };
      this.selected_driver = null;
      this.selected_warehouse = null;
      this.type = null;
      this.id = null;
      this.selected_start_date = new Date(Date.now());
      this.end_date = new Date(Date.now());

      this.place = { name: null, lat: null, lon: null };
      this.trip_destinations = [];
      this.selectedDays = [];
      this.note = null;
      this.days_of_week = null;
      this.bigDialog = false;
      this.resetData();
    },

    addDestination() {
      this.currentDestination = {
        destination_id: null,
        destination_name: null,
        destination_lat: null,
        destination_lon: null,
      };

      this.destinationDialog = true;
      this.trip_destinations.push(this.currentDestination);
      this.currentDestinationIndex = this.trip_destinations.length - 1;
    },

    removeDestination(index) {
      this.trip_destinations.splice(index, 1);
      let len = this.trip_destinations.length;

      if (len === 0) {
        this.destinationDialog = false;
        this.currentDestination = null;
        this.currentDestinationIndex = null;
        return;
      } else {
        if (index > 0) {
          this.currentDestinationIndex = index - 1;
        } else {
          this.currentDestinationIndex = 0;
        }
        this.currentDestination =
          this.trip_destinations[this.currentDestinationIndex];
      }
    },

    updateDestinationName(destination) {
      if (!destination.destination_id) {
        destination.destination_name = "";
        this.clear = true;
        return;
      }
      this.clear = false;

      const selectedDestination = this.destinations.find(
        (dest) => dest.id === destination.destination_id
      );

      if (selectedDestination) {
        destination.destination_name = selectedDestination.name;
      }
    },

    editDestination(destination, index) {
      this.destinationDialog = true;
      this.currentDestination = destination;
      this.currentDestinationIndex = index;
    },

    formatDate(date) {
      if (!date) return "";
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const dayName = days[date.getDay()];
      const monthName = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      const hours = this.pad(date.getHours());
      const minutes = this.pad(date.getMinutes());
      const seconds = this.pad(date.getSeconds());

      return `${dayName} ${monthName} ${day} ${year} ${hours}:${minutes}:${seconds}`;
    },

    resetData() {
      this.destinationDialog = false;
      this.currentDestination = null;
      this.currentDestinationIndex = null;
    },
  },

  watch: {
    selected_start_date: function () {
      api
        .getdrivers()
        .then((res) => {
          this.drivers = [];
          this.driverDisable = true;

          if (res.data.responseCode === 1) {
            this.driverDisable = false;

            res.data.data.forEach((element) => {
              if (element.archived === 1) return;
              let d = {
                id: parseInt(element.id),
                fullname: element.fname + " " + element.lname,
              };
              this.drivers.push(d);
            });
          }
        })
        .catch((error) => {
          this.text = error;
          this.snackbar = true;
          this.loading = false;
          this.errorDialog = true;
        });
    },
    selectedDays(newVal) {
      // Update the days_of_week string whenever selectedDays changes
      this.days_of_week = newVal.join(",");
    },
  },

  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
  },
};
